import './bootstrap';
import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()

const backToTop = document.getElementById('back-to-top');

document.addEventListener('scroll', (event) => {
    if (window.scrollY === 0) {
        backToTop.style.display = 'none';
    } else if (window.scrollY === 296) {
        backToTop.style.display = 'block';
    }
});
